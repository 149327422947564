import { StringLengths } from '@neo1/client';
import {
  Country,
  CountryState,
} from '@neo1/client/lib/entities/referentialData/types';
import { hasStates } from '@neo1/client/lib/entities/referentialData/utils';
import SelectBox from 'components/elements/form/fields/SelectBox';
import TextInput from 'components/elements/form/fields/TextInput';
import { useAppReferentialSingleton } from 'modules/App/useAppReferential';
import { FocusEventHandler } from 'react';

export type Props = {
  countryCode: string | undefined;
  value?: string;
  onChange?: (countryCode: string) => void;
  className?: string;
  id?: string;
  name?: string;
  inputId?: string;
  disabled?: boolean;
  isInvalid?: boolean;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  title?: string;
  errorText?: string;
  isRequired?: boolean;
};

const regionOptions = (regions: Country['states']) =>
  regions.map(({ code, name }) => ({
    label: name,
    value: code,
  }));

export const getStateValue = (
  value: string,
  countryStates: CountryState[],
): string => {
  const countryStateCodes = countryStates.map(({ code }) => code);
  if (countryStateCodes.includes(value)) {
    return value;
  }
  return '';
};

const SelectCountryRegion = ({
  countryCode,
  className,
  disabled,
  value,
  onChange,
  id,
  name,
  inputId,
  isInvalid,
  onFocus,
  onBlur,
  title,
  errorText,
  isRequired,
}: Props) => {
  const { getCountry } = useAppReferentialSingleton();
  const country = getCountry(countryCode);

  if (hasStates(country)) {
    return (
      <SelectBox
        title={title}
        errorText={errorText}
        isInvalid={isInvalid}
        disabled={disabled}
        onChange={onChange}
        id={id || 'selectRegion'}
        name={name}
        inputId={inputId || 'selectRegion__input'}
        options={regionOptions(country.states)}
        className={className}
        sortBy="label"
        value={getStateValue(value, country.states)}
        onFocus={onFocus}
        onBlur={onBlur}
        isRequired={isRequired}
        inputProps={{ autoComplete: 'new-password' }}
        placeholder={`Select ${title.toLowerCase()}`}
      />
    );
  }
  return (
    <TextInput
      title={title}
      errorText={errorText}
      isInvalid={isInvalid}
      maxLength={StringLengths.description}
      id={id}
      name={name}
      disabled={disabled}
      className={className}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      isRequired={isRequired}
      autoComplete="new-password"
      placeholder={`Enter ${title.toLowerCase()}`}
    />
  );
};

SelectCountryRegion.defaultProps = {
  value: undefined,
  onChange: undefined,
  className: undefined,
  id: undefined,
  name: undefined,
  inputId: undefined,
  disabled: false,
  isInvalid: false,
  onFocus: undefined,
  onBlur: undefined,
  title: undefined,
  errorText: undefined,
  isRequired: false,
};

export default SelectCountryRegion;
