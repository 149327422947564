import { combineReducers } from 'redux';
import accounting from '../Accounting/redux/reducer';
import expenseTypesReducer from '../ExpenseCategories/redux/expenseType/reducer';
import expenseCategories from '../ExpenseCategories/redux/expenseCategory/reducer';
import mileage from '../Mileage/redux/reducer';
import tax from '../Taxes/redux/reducer';
import spendRule from '../SpendRules/redux/reducer';
import users from '../People/Users/redux/reducer';
import amexVirtualAccounts from '../General/Integrations/AmexVirtualAccounts/redux/reducer';
import paymentMethods from '../PaymentMethods/redux/reducer';

export default combineReducers({
  accounting,
  mileage,
  tax,
  expenseCategories,
  spendRule,
  expenseType: expenseTypesReducer,
  users,
  myCompany: combineReducers({
    amexVirtualAccounts,
  }),
  paymentMethods,
});
