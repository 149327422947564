import { defineMessages } from 'react-intl';

const messages = defineMessages({
  settingsTitle: {
    id: 'settingsTitle',
    defaultMessage: 'Settings navigation',
  },
  myCompanyPage: {
    id: 'myCompanyPage',
    defaultMessage: 'Business info',
  },
  accountingPage: {
    id: 'accountingPage',
    defaultMessage: 'Fields',
  },
  taxesPage: {
    id: 'taxesPage',
    defaultMessage: 'Taxes',
  },
  paymentMethodsPage: {
    id: 'paymentMethodsPage',
    defaultMessage: 'Payment methods',
  },
  expenseCategoriesPage: {
    id: 'expenseCategoriesPage',
    defaultMessage: 'Expense categories',
  },
  generalConfigurationPage: {
    id: 'generalConfigurationPage',
    defaultMessage: 'General configuration',
  },
  rulesPage: {
    id: 'rulesPage',
    defaultMessage: 'Rules',
  },
  mileagePage: {
    id: 'mileagePage',
    defaultMessage: 'Mileage',
  },
  dataPage: {
    id: 'dataPage',
    defaultMessage: 'Data',
  },
  people: {
    id: 'settingsPeople',
    defaultMessage: 'People',
  },
});

export default messages;
