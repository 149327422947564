import { Fragment, MouseEvent, useState } from 'react';
import classNames from 'classnames';
import Button, {
  ButtonKind,
  ButtonSize,
  ButtonTheme,
} from 'components/elements/Button';
import isEnterOrSpace from 'utils/keyboardEventHelper';
import formatValue from '../formatValue';
import { useSelection } from '../selectionContext';
import { TableProps, TableRowStatus } from '../types';
import Actions from './Actions';
import SelectInput from './SelectInput';
import styles from './TableBody.module.css';
import TableCell from './TableCell';
import TableCellContent from './TableCellContent';

type Props = {
  columns: TableProps['columns'];
  rows: TableProps['rows'];
  rowKeyProp: TableProps['rowKeyProp'];
  onRowClick: TableProps['onRowClick'];
  getRowActions: TableProps['getRowActions'];
  getRowStatus: TableProps['getRowStatus'];
  alwaysDisplayActions: TableProps['alwaysDisplayActions'];
  renderRowDetail: TableProps['rowDetail']['render'];
  toggledRowsByDefault?: TableProps['rowDetail']['toggledRowsByDefault'];
  isOpenByDefault?: TableProps['rowDetail']['isOpenByDefault'];
  hideSelectCheckbox: TableProps['hideSelectCheckbox'];
};

const TableBody = ({
  columns,
  rows,
  rowKeyProp,
  onRowClick,
  getRowActions,
  getRowStatus,
  alwaysDisplayActions,
  renderRowDetail,
  toggledRowsByDefault,
  isOpenByDefault,
  hideSelectCheckbox,
}: Props) => {
  const selectionContext = useSelection();
  const [manuallyToggledRows, setManuallyToggledRows] = useState<string[]>([]);

  const [toggledRows, setToggledRows] = useState(
    toggledRowsByDefault
      ? rows
          .map((r) => {
            const key = r[rowKeyProp];
            return toggledRowsByDefault.includes(key) ? key : undefined;
          })
          .filter((key) => typeof key !== 'undefined')
      : [],
  );

  const toggleRowDetail = (e: MouseEvent, rowKey: string) => {
    setManuallyToggledRows([...manuallyToggledRows, rowKey]);
    // prevent the click event propagation who triggers handleRowClick
    e.stopPropagation();
    if (toggledRows.includes(rowKey)) {
      setToggledRows(toggledRows.filter((key) => key !== rowKey));
    } else {
      setToggledRows([...toggledRows, rowKey]);
    }
  };

  const handleRowClick = (row: Object) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const getRowClasses = (row: Object, rowIdx: number) => {
    const classes = [styles.row];

    if (onRowClick) {
      classes.push(styles.isClickable);
    } else {
      classes.push(styles.notClickable);
    }

    const isEvenRow = (rowIdx + 1) % 2 === 0;
    if (isEvenRow) {
      classes.push(styles.evenRow);
    } else {
      classes.push(styles.oddRow);
    }

    const status = getRowStatus ? getRowStatus(row) : '';
    if (status && status !== TableRowStatus.normal) {
      classes.push(styles[status]);
    }

    return classes;
  };

  return (
    <tbody data-testid="tableBody">
      {rows.map((row, index) => {
        const rowKey = row[rowKeyProp];
        const actions = getRowActions ? getRowActions(row, index) : [];
        const hasActions = actions.length > 0;
        const rowClasses = getRowClasses(row, index);
        const isDetailOpened =
          (isOpenByDefault && !toggledRows.includes(rowKey)) ||
          (!isOpenByDefault && toggledRows.includes(rowKey));
        const rowDetail = renderRowDetail && renderRowDetail(row);
        return (
          <Fragment key={rowKey}>
            <tr
              className={classNames(...rowClasses)}
              onClick={() => handleRowClick(row)}
              onKeyDown={(ev) => {
                if (isEnterOrSpace(ev)) {
                  handleRowClick(row);
                }
              }}
              data-testid="tableRow"
              tabIndex={0}
              data-row-key={rowKey}
            >
              {selectionContext && !hideSelectCheckbox && (
                <td className={styles.cell}>
                  <SelectInput
                    id={rowKey}
                    testId={`rowCheckbox-${row[rowKeyProp]}`}
                  />
                </td>
              )}

              {renderRowDetail && (
                <td className={styles.cell} data-testid="rowDetailToggleCell">
                  {rowDetail && ( // This double check is useful when we have some rows with details and some without in the same table
                    <Button
                      iconName={isDetailOpened ? 'down' : 'right'}
                      iconOnly
                      theme={ButtonTheme.Ghost}
                      size={ButtonSize.Small}
                      kind={ButtonKind.Neutral}
                      label={isDetailOpened ? 'Close' : 'Open'}
                      onClick={(e) => toggleRowDetail(e, rowKey)}
                      testId="rowDetailToggleBtn"
                    />
                  )}
                </td>
              )}

              {columns.map((column, columnIndex) => (
                <TableCell
                  key={column.prop}
                  className={classNames(
                    styles.cell,
                    styles[column.style?.alignment],
                    {
                      [styles.isImportant]: column.style?.important,
                    },
                    column.style?.className,
                  )}
                  width={column.style?.width}
                  colSpan={
                    // when has no actions expand the last column's table cell to take 2 columns' space
                    columnIndex === columns.length - 1 && !hasActions
                      ? 2
                      : undefined
                  }
                >
                  <TableCellContent
                    formattedValue={formatValue(row, column, columnIndex, rows)}
                    className={styles.cellContent}
                  />
                </TableCell>
              ))}

              {/* render the actions' cell only when the row has actions */}
              {/* this is very important for data table rendered in small places */}
              {hasActions && (
                <td className={classNames(styles.actionsCell, styles.cell)}>
                  <Actions
                    actions={actions}
                    className={classNames(styles.actions, {
                      [styles.alwaysDisplayed]: alwaysDisplayActions,
                    })}
                    isTooltipInteractive={false}
                  />
                </td>
              )}
            </tr>

            {rowDetail && isDetailOpened && (
              <tr
                className={classNames(...rowClasses, styles.rowDetail)}
                data-testid="tableRowDetail"
                data-row-key={`tableRowDetail-${rowKey}`}
              >
                <td
                  colSpan={
                    // the colSpan is calculated dynamically to make sure the row detial takes 100% the table width
                    // when table has selection, columns.length + 3 (actions column + open/close row detail icon column + selction checkbox column)
                    // when table has no selection, columns.length + 2 (actions column + open/close row detail icon column)
                    selectionContext ? columns.length + 3 : columns.length + 2
                  }
                >
                  {rowDetail}
                </td>
              </tr>
            )}
          </Fragment>
        );
      })}
    </tbody>
  );
};

TableBody.defaultProps = {
  toggledRowsByDefault: [],
  isOpenByDefault: false,
};

export default TableBody;
