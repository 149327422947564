import { UUID } from '@neo1/client';

export enum Actions {
  SET_EXPENSE_CATEGORIES = 'one/settings/ExpenseTypes/SET_EXPENSE_CATEGORIES ',
  ADD_EXPENSE_CATEGORY = 'one/settings/ExpenseTypes/ADD_EXPENSE_CATEGORY ',
  DEL_EXPENSE_CATEGORY = 'one/settings/ExpenseTypes/DEL_EXPENSE_CATEGORY ',
}

export type SetExpenseCategories = {
  type: Actions.SET_EXPENSE_CATEGORIES;
  ids: UUID[];
};

export function setAll(ids: UUID[]): SetExpenseCategories {
  return { type: Actions.SET_EXPENSE_CATEGORIES, ids };
}

export type AddExpenseCategory = {
  type: Actions.ADD_EXPENSE_CATEGORY;
  id: UUID;
};

export function addOne(id: UUID): AddExpenseCategory {
  return { type: Actions.ADD_EXPENSE_CATEGORY, id };
}

export type DelExpenseCategory = {
  type: Actions.DEL_EXPENSE_CATEGORY;
  id: UUID;
};

export function delOne(id: UUID): DelExpenseCategory {
  return { type: Actions.DEL_EXPENSE_CATEGORY, id };
}
